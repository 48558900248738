import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({
    description,
    lang,
    meta = [],
    title,
    noCookies,
}: {
    description?: string;
    lang: string;
    meta?: any[];
    title: string;
    noCookies?: boolean;
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        author
                        ogImage
                    }
                }
            }
        `,
    );

    const intl = useIntl();
    const mainTitle = intl.formatMessage({ id: 'metadata.title' });
    const mainDescription = intl.formatMessage({ id: 'metadata.description' });
    const metaDescription = description || mainDescription;

    const metaDataEnhanced = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: `${mainTitle} - ${title}`,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `og:image`,
            content: site.siteMetadata.ogImage,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
        },
        {
            name: `twitter:title`,
            content: `${mainTitle} - ${title}`,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
    ].concat(meta);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${mainTitle}`}
            meta={noCookies ? meta : metaDataEnhanced}
        >
            {noCookies ? (
                <meta name="robots" content="noindex" />
            ) : (
                <script type="application/javascript" src="https://app.usercentrics.eu/latest/main.js" id="6Np1xmihd" />
            )}
        </Helmet>
    );
}

export default SEO;
