import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import Button, { ButtonThemeColor, ButtonThemeType } from '../../templates/button/button';

import TopNavigationStyles from './top-navigation.module.scss';

interface NavigationEntry {
    text: string;
    link: string;
    logo?: 'cycle' | 'mirror';
    subMenu?: Array<{ text: string; link: string }>;
}

const TopNavigation = () => {
    const intl = useIntl();
    const [navigationEntries, setNavigationEntries] = React.useState<NavigationEntry[]>([]);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [backgroundActive, setBackgroundActive] = React.useState(false);
    const [subMenuActive, setSubMenuActive] = React.useState<string | undefined>(undefined);
    const [scrolled, setScrolled] = React.useState(false);

    const toggleMenu = (e: any) => {
        setMenuOpen(!menuOpen);
        e.target.blur();
    };

    const toggleSubMenu = (subMenuId: string) => {
        if (subMenuActive) {
            setSubMenuActive(undefined);
        } else {
            setSubMenuActive(subMenuId);
        }
    };

    const checkScrollPosition = () => {
        if (menuOpen) {
            return;
        }
        if (window.scrollY < 10) {
            setScrolled(false);
        } else {
            setScrolled(true);
            setSubMenuActive(undefined);
        }
    };

    const getLogoClass = (logoType: 'mirror' | 'cycle'): string => {
        if (logoType === 'mirror') {
            return TopNavigationStyles.logoMirror;
        } else {
            return TopNavigationStyles.logoCycle;
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', checkScrollPosition);
            window.addEventListener('resize', closeMenu);

            return () => {
                window.removeEventListener('scroll', checkScrollPosition);
                window.removeEventListener('resize', closeMenu);
            };
        }
    }, []);

    React.useEffect(() => {
        setBackgroundActive(menuOpen ? true : scrolled);
    }, [menuOpen]);

    React.useEffect(() => {
        const localizationData = require('../../../_localization/' + intl.locale + '.json');
        setNavigationEntries(localizationData.components.topNavigation.menu);
    }, []);

    React.useEffect(() => {
        if (!menuOpen) {
            if (scrolled) {
                setBackgroundActive(true);
            } else {
                setBackgroundActive(false);
            }
        }
    }, [scrolled]);

    return (
        <header
            className={[
                TopNavigationStyles.topNavigation,
                backgroundActive ? TopNavigationStyles.backgroundActive : '',
                subMenuActive ? TopNavigationStyles.submenuOpenedTop : '',
            ].join(' ')}
            onMouseOver={() => setBackgroundActive(true)}
            onMouseLeave={() => {
                if (!menuOpen && !scrolled) {
                    setBackgroundActive(false);
                }
            }}
        >
            <div className={TopNavigationStyles.indentation}>
                <RemoveScroll enabled={menuOpen}>
                    <nav className={[TopNavigationStyles.menu, menuOpen ? TopNavigationStyles.menuOpen : ''].join(' ')}>
                        <ul>
                            {navigationEntries.map((mainEntry: NavigationEntry, mainIndex: number) => (
                                <li
                                    key={mainIndex}
                                    className={
                                        mainEntry.subMenu && subMenuActive === mainEntry.text
                                            ? TopNavigationStyles.subMenuOpened
                                            : undefined
                                    }
                                >
                                    <Link
                                        to={mainEntry.link}
                                        className={mainEntry.logo ? getLogoClass(mainEntry.logo) : undefined}
                                        activeClassName={TopNavigationStyles.menuActiveLink}
                                        onClick={(e) => {
                                            if (mainEntry.subMenu) {
                                                e.preventDefault();
                                                toggleSubMenu(mainEntry.text);
                                                (e.target as HTMLLinkElement).blur();
                                            }
                                        }}
                                    >
                                        <FormattedMessage id={'components.topNavigation.menu.' + mainIndex + '.text'} />
                                        {mainEntry.subMenu && <span>{'>'}</span>}
                                    </Link>
                                    {mainEntry.subMenu && (
                                        <ul>
                                            {mainEntry.subMenu.map((subEntry: any, subIndex: number) => (
                                                <li key={mainIndex + '-' + subIndex}>
                                                    <Link
                                                        to={subEntry.link}
                                                        activeClassName={TopNavigationStyles.subMenuActiveLink}
                                                        onClick={() => setSubMenuActive(undefined)}
                                                    >
                                                        <FormattedMessage
                                                            id={
                                                                'components.topNavigation.menu.' +
                                                                mainIndex +
                                                                '.subMenu.' +
                                                                subIndex +
                                                                '.text'
                                                            }
                                                        />
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                            <li>
                                <div className={TopNavigationStyles.button}>
                                    <Button
                                        type="link"
                                        href={intl.formatMessage({ id: 'components.topNavigation.ctaButton.link' })}
                                        target="shop"
                                        theme={{ type: ButtonThemeType.Gradient, color: ButtonThemeColor.BlueRose }}
                                    >
                                        <FormattedMessage id={'components.topNavigation.ctaButton.text'} />
                                    </Button>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </RemoveScroll>

                <div className={TopNavigationStyles.buttonBuyNow}>
                    <Button
                        type="link"
                        href={intl.formatMessage({ id: 'components.topNavigation.ctaButton.link' })}
                        target="shop"
                        theme={
                            backgroundActive
                                ? { type: ButtonThemeType.Gradient, color: ButtonThemeColor.BlueRose }
                                : { type: ButtonThemeType.NavigationWhite, color: ButtonThemeColor.BlueRose }
                        }
                    >
                        <FormattedMessage id={'components.topNavigation.ctaButton.text'} />
                    </Button>
                </div>

                <Link
                    to={'/'}
                    aria-label={intl.formatMessage({ id: 'components.topNavigation.homeAriaLabel' })}
                    className={TopNavigationStyles.logo}
                    tabIndex={2}
                >
                    {' '}
                </Link>

                <button
                    className={[
                        TopNavigationStyles.menuToggle,
                        menuOpen ? TopNavigationStyles.menuToggleOpen : '',
                    ].join(' ')}
                    onClick={toggleMenu}
                    tabIndex={1}
                >
                    <span />
                    <span />
                    <span />
                    <span />
                </button>
            </div>
        </header>
    );
};

export default TopNavigation;
