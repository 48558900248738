import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import AssetsDataContext from '../../../context/assets-data.context';
import Footer from '../../navigation/footer/footer';
import LoadingOverlay from '../loading-overlay/loading-overlay';
import SEO from '../seo/seo';
import TopNavigation from '../../navigation/top-navigation/top-navigation';
import './page-layout.scss';

export enum PageLayoutBackground {
    Standard = 'gradient-background-static',
    Animated = 'gradient-background-animated',
    Dark = 'gradient-background-static-dark',
    PinkAqua = 'gradient-background-static-pink-aqua',
    PinkBlack = 'gradient-background-static-pink-black',
    Black = 'background-color-black',
    Grey = 'gradient-background-static-grey',
}

export interface PageLayoutProperties {
    children: any;
    title: string;
    description?: string;
    noCookies?: boolean;
    topPadding?: boolean;
    pageBackground?: PageLayoutBackground;
}

const PageLayout = ({
    children,
    title,
    description,
    noCookies = false,
    topPadding = true,
    pageBackground = PageLayoutBackground.Standard,
}: PageLayoutProperties) => {
    const { assetsData } = React.useContext(AssetsDataContext);
    const intl = useIntl();

    return (
        <>
            <SEO
                lang={intl.locale}
                title={title}
                description={description ?? undefined}
                noCookies={noCookies}
                meta={[{ name: 'google-site-verification', content: 'JKS-9G2VXgtQH2Mivl_Qsn_HnyfELyKO_tF5OZdU70s' }]}
            />

            <div className={['page-layout', pageBackground].join(' ')}>
                {assetsData.images.edges.length > 0 ? (
                    <>
                        <TopNavigation />
                        <main className={topPadding ? undefined : 'no-top-padding'}>{children}</main>
                        <Footer />
                    </>
                ) : (
                    <LoadingOverlay active={true} />
                )}
            </div>
        </>
    );
};

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageLayout;
