import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

export const richText = (message: string): React.ReactFragment => {
    const intl = useIntl();

    if (!intl.messages[message]) {
        return <>{'\u00A0'}</>;
    }

    return (
        <FormattedMessage
            id={message}
            values={{
                sup: (...chunks: any) => {
                    const anchor = '#footnote-' + chunks;
                    return (
                        <sup>
                            <a href={anchor}>{chunks}</a>
                        </sup>
                    );
                },
                strong: (...chunks: any) => <span className="bold">{chunks}</span>,
                wide: (...chunks: any) => <span className="wide">{chunks}</span>,
                a: (...chunks: any) => {
                    const linkAttributes = chunks.toString().split('|');
                    const link = {
                        href: '',
                        target: '',
                        text: '',
                        rel: '',
                    };
                    if (linkAttributes.length === 1) {
                        link.href = linkAttributes[0];
                        link.text = linkAttributes[0];
                    } else if (linkAttributes.length === 2) {
                        link.href = linkAttributes[0];
                        link.text = linkAttributes[1];
                    } else {
                        link.href = linkAttributes[0];
                        link.text = linkAttributes[1];
                        link.target = linkAttributes[2];
                        if (link.target !== '_self') {
                            link.rel = 'noopener noreferrer';
                        }
                    }
                    return (
                        <a href={link.href} target={link.target} rel={link.rel}>
                            {link.text}
                        </a>
                    );
                },
            }}
        />
    );
};
