import React from 'react';

import LoadingOverlayStyles from './loading-overlay.module.scss';

const LoadingOverlay = ({ active }: { active: boolean }) => {
    return (
        <>
            {active && (
                <div className={LoadingOverlayStyles.loadingOverlay}>
                    <div className={LoadingOverlayStyles.inner}>
                        <div className={LoadingOverlayStyles.content}>
                            <span className={LoadingOverlayStyles.spinner} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoadingOverlay;
