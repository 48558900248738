import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FormEvent } from 'react';
import CheckboxStyle from '../../../_scss/modules/checkbox.module.scss';
import { emailCheck } from '../../../modules/email-check';
import { richText } from '../../../modules/rich-text';
import Button, { ButtonTheme } from '../../templates/button/button';

import NewsletterStyles from './newsletter.module.scss';

export interface NewsletterProperties {
    headline: string;
    buttonTheme: ButtonTheme;
    theme: 'page' | 'footer';
}

const Newsletter = ({ headline, buttonTheme, theme }: NewsletterProperties) => {
    const intl = useIntl();
    const [email, setEmail] = React.useState('');
    const [isEmailValid, setIsEmailValid] = React.useState(false);
    const [disclaimer, setDisclaimer] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [interestCycle, setInterestCycle] = React.useState(false);
    const [interestMirror, setInterestMirror] = React.useState(false);

    const newsletterForm = React.useRef(null);
    const random = '_' + Math.random().toString(36).substr(2, 9);

    const submitNewsletterSubscription = (e?: FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        const checkFormatResult = emailCheck(email);
        setIsEmailValid(checkFormatResult);
        if (disclaimer && checkFormatResult && (interestCycle || interestMirror)) {
            // @ts-ignore
            newsletterForm.current.submit();
        } else {
            setShowErrors(true);
        }
    };

    return (
        <div className={[NewsletterStyles.newsletter, NewsletterStyles[theme]].join(' ')}>
            <form
                ref={newsletterForm}
                action="https://tedd91d3d.emailsys1a.net/3/5361/4fcdd27936/subscribe/form.html"
                method="post"
                onSubmit={submitNewsletterSubscription}
            >
                <input type="hidden" name="consent_text" value="yes" />
                <input type="hidden" name="captcha" value="" />

                <div
                    className={[
                        NewsletterStyles.emailField,
                        showErrors && !isEmailValid ? NewsletterStyles.emailFieldError : '',
                    ].join(' ')}
                >
                    <label htmlFor={'newsletter-email-' + random}>{headline}</label>

                    <div>
                        <div>
                            <input
                                name="email"
                                type="email"
                                id={'newsletter-email-' + random}
                                placeholder={intl.formatMessage({
                                    id: 'components.bottomNavigation.newsletterForm.emailPlaceholder',
                                })}
                                autoComplete="email"
                                value={email}
                                enterKeyHint="send"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setShowErrors(false);
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                type="button"
                                theme={buttonTheme}
                                className={NewsletterStyles.button}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    submitNewsletterSubscription();
                                }}
                            >
                                <FormattedMessage id={'components.bottomNavigation.newsletterForm.submitButton'} />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={NewsletterStyles.interest}>
                    <p className={showErrors && !(interestCycle || interestMirror) ? NewsletterStyles.error : ''}>
                        Ich interessiere mich für{' '}
                        <label htmlFor={'newsletter-cycle-' + random}>
                            <input
                                id={'newsletter-cycle-' + random}
                                type="checkbox"
                                name="tags[]"
                                value="cycle"
                                onChange={(e) => {
                                    setInterestCycle(e.target.checked);
                                    setShowErrors(false);
                                }}
                            />
                            @CYCLE
                        </label>{' '}
                        <label htmlFor={'newsletter-mirror-' + random}>
                            <input
                                id={'newsletter-mirror-' + random}
                                type="checkbox"
                                name="tags[]"
                                value="mirror"
                                onChange={(e) => {
                                    setInterestMirror(e.target.checked);
                                    setShowErrors(false);
                                }}
                            />
                            @MIRROR
                        </label>
                    </p>
                </div>

                <div
                    className={[
                        NewsletterStyles.disclaimerField,
                        CheckboxStyle.checkbox,
                        showErrors && !disclaimer ? CheckboxStyle.error : '',
                    ].join(' ')}
                >
                    <input
                        type="checkbox"
                        id={'newsletter-disclaimer-' + random}
                        onChange={(e) => {
                            setDisclaimer(e.target.checked);
                            setShowErrors(false);
                        }}
                    />
                    <label htmlFor={'newsletter-disclaimer-' + random}>
                        {richText('components.bottomNavigation.newsletterForm.disclaimerLabel')}
                    </label>
                </div>
            </form>
        </div>
    );
};

export default Newsletter;
