import { Link } from 'gatsby-plugin-intl';
import React from 'react';

import ButtonStyles from './button.module.scss';

export interface ButtonProperties {
    children?: any;
    type?: 'button' | 'link';
    onClick?: any;
    className?: string;
    theme: ButtonTheme;
    to?: string;
    href?: string;
    target?: string;
    tabIndex?: number;
}

export interface ButtonTheme {
    type: ButtonThemeType;
    color: ButtonThemeColor;
}

export enum ButtonThemeType {
    NavigationWhite = 'themeTypeNavigationWhite',
    Gradient = 'themeTypeGradient',
    OutlineWhite = 'themeTypeOutlineWhite',
    OutlineBlack = 'themeTypeOutlineBlack',
    SolidWhite = 'themeTypeSolidWhite',
    SolidBlack = 'themeTypeSolidBlack',
}

export enum ButtonThemeColor {
    BlueRose = 'themeColorBlueRose',
    PinkAqua = 'themeColorPinkAqua',
    PurpleGreen = 'themeColorPurpleGreen',
    RedYellow = 'themeColorRedYellow',
    Blue = 'themeColorBlue',
    Pink = 'themeColorPink',
    Purple = 'themeColorPurple',
    Red = 'themeColorRed',
    Rose = 'themeColorRose',
    White = 'themeColorWhite',
    Black = 'themeColorBlack',
    Transparent = 'themeColorTransparent',
}

const Button = ({
    children,
    type = 'button',
    onClick,
    className = '',
    theme,
    href,
    tabIndex,
    target,
    to,
}: ButtonProperties) => {
    return (
        <div className={[ButtonStyles.buttonWrapper, className].join(' ')}>
            {href ? (
                <a
                    href={href}
                    target={target ?? '_blank'}
                    className={[ButtonStyles.button, ButtonStyles[theme.type], ButtonStyles[theme.color]].join(' ')}
                    onClick={onClick}
                    tabIndex={tabIndex ? tabIndex : 0}
                    rel="noopener noreferrer"
                >
                    {children}
                </a>
            ) : (
                <Link
                    role={type}
                    to={to ?? '#'}
                    className={[ButtonStyles.button, ButtonStyles[theme.type], ButtonStyles[theme.color]].join(' ')}
                    onClick={onClick}
                    tabIndex={tabIndex ? tabIndex : 0}
                >
                    {children}
                </Link>
            )}
        </div>
    );
};

export default Button;
