import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Newsletter from '../../content/newsletter/newsletter';
import { ButtonThemeColor, ButtonThemeType } from '../../templates/button/button';

import FooterStyles from './footer.module.scss';

interface NavigationEntry {
    text: string;
    link: string;
    target?: string;
}

const Footer = () => {
    const intl = useIntl();
    const [navigationEntries, setNavigationEntries] = React.useState<NavigationEntry[]>([]);

    React.useEffect(() => {
        const localizationData = require('../../../_localization/' + intl.locale + '.json');
        setNavigationEntries(localizationData.components.bottomNavigation.navigation);
    }, []);

    return (
        <footer className={FooterStyles.footer}>
            <div className={FooterStyles.footerInner}>
                <Newsletter
                    headline={'BLEIB UP TO DATE MIT HORIZON@:'}
                    buttonTheme={{ type: ButtonThemeType.Gradient, color: ButtonThemeColor.BlueRose }}
                    theme="footer"
                />

                <div className={FooterStyles.socialMedia}>
                    <a
                        href={intl.formatMessage({ id: 'components.bottomNavigation.socialMediaLinks.facebook.link' })}
                        className={[FooterStyles.icon, FooterStyles.iconFacebook].join(' ')}
                        target="facebook"
                        rel="noopener noreferrer"
                        aria-label={intl.formatMessage({
                            id: 'components.bottomNavigation.socialMediaLinks.facebook.ariaLabel',
                        })}
                    />
                    <a
                        href={intl.formatMessage({ id: 'components.bottomNavigation.socialMediaLinks.instagram.link' })}
                        className={[FooterStyles.icon, FooterStyles.iconInstagram].join(' ')}
                        target="instagram"
                        rel="noopener noreferrer"
                        aria-label={intl.formatMessage({
                            id: 'components.bottomNavigation.socialMediaLinks.instagram.ariaLabel',
                        })}
                    />
                    <a
                        href={intl.formatMessage({ id: 'components.bottomNavigation.socialMediaLinks.youtube.link' })}
                        className={[FooterStyles.icon, FooterStyles.iconYoutube].join(' ')}
                        target="youtube"
                        rel="noopener noreferrer"
                        aria-label={intl.formatMessage({
                            id: 'components.bottomNavigation.socialMediaLinks.youtube.ariaLabel',
                        })}
                    />
                </div>

                <nav className={FooterStyles.navigation}>
                    <ul>
                        {navigationEntries.map((entry: NavigationEntry, index: number) => (
                            <li key={index}>
                                {entry.link.startsWith('http') ? (
                                    <a href={entry.link} target={entry.target ?? ''} rel="noopener noreferrer">
                                        {entry.text}
                                    </a>
                                ) : (
                                    <Link activeClassName={FooterStyles.activeLink} to={entry.link}>
                                        {entry.text}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
